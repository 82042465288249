import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TalepModel } from 'src/app/models/talepModel';
import { TalepService } from 'src/app/services/talep.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';

import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { ServisListModelOut } from 'src/app/models/ServisListModelOut';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-talep',
  templateUrl: './talep.component.html',
  styleUrls: ['./talep.component.css'],
})
export class TalepComponent implements OnInit {
  constructor(
    private router: Router,
    private talepService: TalepService,
    private toastrService: ToastrService
  ) {}
  taleps: TalepModel[] = [];
  servislist: ServisListModelOut[] = [];
  dataLoaded = false;
  isontalepgoster = false;
  istalepgoster = false;

  listData: MatTableDataSource<any>;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  listData2: MatTableDataSource<any>;
  @ViewChild(MatSort) sort2: MatSort;
  @ViewChild(MatPaginator) paginator2: MatPaginator;

  ngOnInit(): void {
    this.isontalepgoster = environment.isontalepgoster;
    this.istalepgoster = environment.istalepgoster;

    if (this.isontalepgoster) {
      this.TalepList();
    }

    /*if (this.istalepgoster) {
    
    }*/
    this.GetServisList();
  }

 displayedColumns: string[] = ['istekid','musteriadsoyad',  'konu', 'crttar', 'servisId'];
 displayedColumns2: string[] = ['Id','teslimeden',  'konu', 'InsertDate','personeladsoyad','kapanmatarihi', 'servisId'];
  TalepList() {
    this.talepService.GetTalepList().subscribe((a) => {
      this.dataLoaded = true;
     
      this.taleps = a.Veri;
      if (a.BasariliMi) {
        this.listData = new MatTableDataSource(this.taleps);

        this.listData.sort = this.sort;
        this.listData.paginator = this.paginator;
      } else {
        this.toastrService.info(a.Mesaj, 'Dikkat');
      }
    });
  }

  GetServisList() {
    this.talepService.GetServisList().subscribe((a) => {
      
      this.dataLoaded = true;
      this.servislist = a.Veri;

      if (a.BasariliMi) {
        this.listData2 = new MatTableDataSource(this.servislist);

        this.listData2.sort = this.sort2;
        this.listData2.paginator = this.paginator2;
      } else {
        this.toastrService.info(a.Mesaj, 'Dikkat');
      }
    });
  }

  routerDetay(item: ServisListModelOut) {
    this.router.navigate(['/talepdetay/' + item.ServisId]);
  }

  deleteBrand(brand: any) {}
}
