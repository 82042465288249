// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  //apiUrl: 'http://localhost:22900/api/',
  apiUrl: 'https://api.servispozitif.com/api/',
  kurumid : 18146,
  production: true,
  isontalepgoster : true,
  istalepgoster : false,
  logo : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtZBevLRxNwSROCIRELPBqkRbt8sZMHVgu5JQfynI13WptqQP-",
  footer1:"info@milkar.com.tr - Milkar - Milkar İnşaat - Bizimle Güvendesiniz",
  footer2:"Whatsapp Destek: +90 312 441 43 43 - Çağrı Merkezi: 0312 441 43 43 - GSM:5326006076 - teknikservis@milkar.com.tr"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
